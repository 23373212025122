<template>
  <div class="flex flex-col box items-center p-4 gap-4">
    <section v-if="pagina === 1" class="flex flex-col gap-4 w-1/2 p-4 justify-center">

      <div class="flex flex-col gap-2">
        <label>Area</label>
        <MultiSelect
          v-model="values.areas"
          :options="areas"
          optionLabel="name"
          optionValue="id"
          placeholder="Seleccione un area"
          display="chip"
          class="multiselect-custom p-inputtext-xs text-xs"
        />
        <span class="text-red-500 text-xs">{{ errors.areas }}</span>
      </div>

      <div class="flex flex-col gap-4" v-if="rutaLength > 0">

        <div class="flex flex-col gap-2" v-if="rutaLength > 0">
          <label>Tipo de procesos</label>
          <AutoComplete
            v-model="values.tipoProceso"
            :suggestions="tiposProcesos"
            @complete="buscar($event, 1)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeAutoComplete('tipoProceso')"
            placeholder="Digite el tipo de proceso"
          />
          <span class="text-xs text-red-500" v-if="errors.tipoProceso">{{ errors.tipoProceso }}</span>
        </div>

        <div class="flex flex-col gap-2" v-if="rutaLength > 1">
          <label>Procesos</label>
          <AutoComplete
            v-model="values.proceso"
            :suggestions="procesos"
            @complete="buscar($event, 2)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeAutoComplete('proceso')"
            placeholder="Digite el proceso"
          />
          <span class="text-xs text-red-500" v-if="errors.proceso">{{ errors.proceso }}</span>
        </div>

        <div class="flex flex-col gap-2" v-if="rutaLength > 2">
          <label>Carpetas</label>
          <AutoComplete
            v-model="values.carpeta"
            :suggestions="carpetas"
            @complete="buscar($event, 3)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeAutoComplete('carpeta')"
            placeholder="Digite la carpeta"
          />
          <span class="text-xs text-red-500" v-if="errors.carpeta">{{ errors.carpeta }}</span>
        </div>

        <div class="flex flex-col gap-2" v-if="rutaLength > 3">
          <label>Documentos</label>
          <AutoComplete
            v-model="values.documento"
            :suggestions="documentos"
            @complete="buscar($event, 4)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeAutoComplete('documento')"
            placeholder="Digite el documento"
          />
          <span class="text-xs text-red-500" v-if="errors.documento">{{ errors.documento }}</span>
        </div>
      </div>

      <div class="flex flex-col gap-2 w-full">
        <label>Titulo</label>
        <InputText
          v-model="values.asunto"
          class="p-inputtext-xs"
          placeholder="Escriba el titulo de la solicitud"
        />
        <span class="text-red-500 text-xs">{{ errors.asunto }}</span>
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label>Solicitud</label>
        <Textarea
          v-model="values.solicitud"
          rows="3"
          cols="30"
          class="p-inputtext-xs"
          placeholder="Escriba la solicitud"
        />
        <span class="text-red-500 text-xs">{{ errors.solicitud }}</span>
      </div>

    </section>
    <section v-else-if="pagina === 2" class="w-full h-full">
      <Editor
        ref="editor"
        api-key="bk8rtsyvxlv71yhl5xdunqtik4gffjibzq2u0sh7iofxjvvz"
        :init="{
                plugins: 'lists link image   table',
                language: 'es',
                width: '100%',
                height: '500',
              }"
        v-model="dataEditor"
      />
    </section>
    <div class="flex justify-center gap-4">
      <Button
        label="Cancelar"
        class="p-button-outlined"
        @click="$router.go(-1)"
        v-if="pagina === 1"
      />
      <Button
        label="Atras"
        class="p-button-outlined"
        @click="pagina--"
        v-else
      />
      <Button
        label="Guardar"
        @click="guardar"
        v-if="switchAccion || pagina === 2"
      />
      <Button
        label="Siguiente"
        @click="pagina++"
        v-else
      />
    </div>
  </div>
</template>

<script>

import Editor from '@tinymce/tinymce-vue'
import { computed, ref, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import store from '../store'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'

export default {
  name: 'EditarSolicitud',
  components: {
    Editor
  },
  setup () {
    // Computed
    const route = useRoute()
    const router = useRouter()
    const getSolicitud = computed(() => store.getters.getSolicitud)
    const rutaLength = computed(() => getSolicitud.value.ruta.split(' > ').length)

    // Referencias
    const _modalSolicitudesService = new ModalSolicitudesService()
    const areas = ref([])
    const pagina = ref(1)
    const tiposProcesos = ref([])
    const tipoProcesoId = ref(null)
    const procesos = ref([])
    const procesoId = ref(null)
    const carpetas = ref([])
    const carpetaId = ref(null)
    const documentos = ref([])
    const documentoId = ref(null)
    const switchAccion = ref(true)
    const dataEditor = ref('')

    // Validaciones
    const schema = yup.object({
      areas: yup.array().nullable().min(1, 'Debe seleccionar al menos 1 area').required('El area es requerida'),
      rutaLength: yup.number().required('El tipo de proceso es requerido'),
      tipoProceso: yup.string().when('rutaLength', {
        is: (rutaLength) => rutaLength > 0,
        then: yup.string().nullable().required('El tipo de proceso es requerido').label(''),
        otherwise: yup.string().nullable(true)
      }),
      proceso: yup.string().when('rutaLength', {
        is: (rutaLength) => rutaLength > 1,
        then: yup.string().nullable().required('El proceso es requerido').label(''),
        otherwise: yup.string().nullable(true)
      }),
      carpeta: yup.string().when('rutaLength', {
        is: (rutaLength) => rutaLength > 2,
        then: yup.string().nullable().required('La carpeta es requerida').label(''),
        otherwise: yup.string().nullable(true)
      }),
      documento: yup.string().when('rutaLength', {
        is: (rutaLength) => rutaLength > 3,
        then: yup.string().nullable().required('El documento es requerido').label(''),
        otherwise: yup.string().nullable(true)
      })
    })
    const { errors, values, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('areas', null, { initialValue: null })
    useField('tipoProceso', null, { initialValue: null })
    useField('proceso', null, { initialValue: null })
    useField('carpeta', null, { initialValue: null })
    useField('documento', null, { initialValue: null })

    // Services

    // Data

    // Computed

    // Methods
    const guardar = handleSubmit((values) => {
      values.dataEditor = dataEditor.value
      values.ruta = `${rutaLength.value > 0 ? values.tipoProceso : ''}${rutaLength.value > 1 ? ' > ' + values.proceso : ''}${rutaLength.value > 2 ? ' > ' + values.carpeta : ''}${rutaLength.value > 3 ? ' > ' + values.documento : ''}`
      _modalSolicitudesService.corregirSolicitud(getSolicitud.value.id, values).then((res) => {
        router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.solicitudes' })
      })
    })

    const buscar = (event, tipoId) => {
      // Tipo 1 = Tipo de proceso, 2 = Proceso, 3 = Carpeta, 4 = Documento
      switch (tipoId) {
        case 1:
          _modalSolicitudesService.getTiposProcesos({ nombre: event.query }).then((res) => {
            tiposProcesos.value = res.data
          })
          break
        case 2:
          _modalSolicitudesService.getProcesos({
            nombre: event.query,
            padre: tipoProcesoId.value
          }).then((res) => {
            procesos.value = res.data
          })
          break
        case 3:
          _modalSolicitudesService.getCarpetas({
            nombre: event.query,
            padre: procesoId.value
          }).then((res) => {
            carpetas.value = res.data
          })
          break
        case 4:
          _modalSolicitudesService.getDocumentos({
            nombre: event.query,
            padre: carpetaId.value
          }).then((res) => {
            documentos.value = res.data
          })
          break
      }
    }
    const onChangeAutoComplete = (tipo) => {
      switch (tipo) {
        case 'tipoProceso': {
          tipoProcesoId.value = values.tipoProceso.id
          values.tipoProceso = values.tipoProceso.nombre
          break
        }
        case 'proceso': {
          procesoId.value = values.proceso.id
          values.proceso = values.proceso.nombre
          break
        }
        case 'carpeta': {
          carpetaId.value = values.carpeta.id
          values.carpeta = values.carpeta.nombre
          break
        }
        case 'documento': {
          documentoId.value = values.documento.id
          values.documento = values.documento.nombre
          break
        }
      }
    }

    // Watchers

    // OnMounted
    onBeforeMount(() => {
        store.dispatch('getSolicitud', route.params.id).then(async () => {
          values.areas = getSolicitud.value.areasSolici.map((item) => item.areaId)
          const ruta = getSolicitud.value.ruta.split(' > ')
          values.rutaLength = ruta.length
          values.asunto = getSolicitud.value.asunto
          values.solicitud = getSolicitud.value.solicitud
          if (getSolicitud.value.tipoId !== 3 && rutaLength.value === 4) {
            switchAccion.value = false
          }

          await _modalSolicitudesService.getTiposProcesos({ nombre: ruta[0] }).then(({ data }) => {
            if (data.length > 0) {
              values.tipoProceso = data[0].nombre
              tipoProcesoId.value = data[0].id
            } else {
              values.tipoProceso = ruta[0]
            }
          })

          if (rutaLength.value > 1) {
            await _modalSolicitudesService.getProcesos({
              nombre: ruta[1],
              padre: tipoProcesoId.value
            }).then(({ data }) => {
              if (data.length > 0) {
                values.proceso = data[0].nombre
                procesoId.value = data[0].id
              } else {
                values.proceso = ruta[1]
              }
            })
          }

          if (rutaLength.value > 2) {
            await _modalSolicitudesService.getCarpetas({
              nombre: ruta[2],
              padre: procesoId.value
            }).then(({ data }) => {
              if (data.length > 0) {
                values.carpeta = data[0].nombre
                carpetaId.value = data[0].id
              } else {
                values.carpeta = ruta[2]
              }
            })
          }
          if (rutaLength.value > 3) {
            await _modalSolicitudesService.getDocumentos({
              nombre: ruta[3],
              padre: carpetaId.value
            }).then(({ data }) => {
              if (data.length > 0) {
                values.documento = data[0].nombre
                documentoId.value = data[0].id
              } else {
                values.documento = ruta[3]
              }
            })
          }
          _modalSolicitudesService.obtenerHtmlBySolicitud(getSolicitud.value.id).then(({ data }) => {
            dataEditor.value = data.html
          })
        })
        _modalSolicitudesService.getAreasList().then((res) => {
          areas.value = res.data
        })
      }
    )

    return {
      getSolicitud,
      areas,
      pagina,
      errors,
      values,
      handleReset,
      guardar,
      rutaLength,
      tiposProcesos,
      procesos,
      carpetas,
      documentos,
      buscar,
      onChangeAutoComplete,
      switchAccion,
      dataEditor
    }
  }
}
</script>

<style scoped>

</style>
